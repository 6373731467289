<script src="https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit" async defer>
</script>
<template>
  <div
    class="auth-wrapper auth-v2"
    style="max-height:100% !important; overflow: hidden"
  >
    <ejs-toast
      id="toast_type"
      ref="toastRef"
      :position="{ X: 'Right' }"
    />
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo mb-5 mb-lg-0">
        <vuexy-logo class="" />
        <h2 class="brand-text text-primary ml-1">
          <!-- VET -->
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="7"
        xl="8"
        class="d-none d-lg-flex align-items-center bg-sea"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center"
        >
          <kinesis-container
            style=""
          >
            <kinesis-element
              :strength="30"
              type="depth"
              class=""
            >
              <!-- <b-img
                fluid
                :src="require('@/@core/assets/logo/logo.png')"
                alt="Login V2"
                width="600"
                heigth="400"
              /> -->
              <b-img
                fluid
                :src="require('@/@core/assets/logo/marchio-intero-fishup.svg')"
                alt="Login V2"
                width="600"
                heigth="400"
              />
            </kinesis-element>
          </kinesis-container>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="5"
        xl="4"
        class="d-flex align-items-center auth-bg p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Benvenuto in {{ appName }} 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Entra con il tuo account e inizia l'avventura!
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="validationForm"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    class=""
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{name:'reset-password'}">
                    <small>Password dimenticata?</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  plain
                  name="checkbox-1"
                >
                  Ricordami?
                </b-form-checkbox>
              </b-form-group>
              <div
                :class="{ recaptchaVisible: userEmail.length >= 5 && password.length >= 8 }"
                class="mb-2 recaptchaNotVisible"
              >
                <Recaptcha
                  @recaptcha-verified="verifiedRecaptcha"
                  @recaptcha-exipered="recaptchaExipered"
                />
              </div>

              <!-- submit buttons -->
              <b-button
                variant="primary"
                block
                @click="validationForm"
              >
                <div class="d-flex justify-content-center">
                  <div class="mr-1">
                    <span>Login</span>
                  </div>
                  <b-spinner
                    v-if="loading"
                    small
                  />
                </div>
              </b-button>
            </b-form>
          </validation-observer>
          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">
              oppure
            </div>
          </div> -->

          <!-- social buttons -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <p class="d-block">
              Nuovo sulla piattaforma?
            </p>
            <b-link :to="{name:'register'}">
              <span>&nbsp;Registra un account</span>
            </b-link>
          </div> -->
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue'
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BSpinner,
} from 'bootstrap-vue'
import { ToastPlugin } from '@syncfusion/ej2-vue-notifications'
import { $themeConfig } from '@themeConfig'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Recaptcha from '@core/components/recaptcha/Recaptcha.vue'
import { login }  from '@/@core/api-service/authotization/auth.js'
import { KinesisContainer, KinesisElement } from 'vue-kinesis'
import store from '@/store'

Vue.use(ToastPlugin)
export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    KinesisContainer,
    KinesisElement,
    BSpinner,
    // $themeConfig,
    Recaptcha,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      captchaConfirmed: false,
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      errorLogin: '',
      // validation rulesimport store from '@/store/index'
      required,
      email,
      appName: $themeConfig.app.appName,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    locale() {
      return 'it'
    },
  },
  mounted() {
    localize(this.locale)
  },
  methods: {
    setActiveAgency() {
      const connectionOptions = JSON.parse(sessionStorage.getItem('organization'))
      const activeAgency = connectionOptions.map(el => ({ title: el.ragione_sociale, value: el.chiave }))
      sessionStorage.setItem('activeAgency', JSON.stringify(activeAgency[0]))
    },
    verifiedRecaptcha() {
      this.captchaConfirmed = true
      //this.validationForm()
    },
    recaptchaExipered() {
      this.captchaConfirmed = false
    },
    validationForm() {
      if(this.captchaConfirmed) {
        this.$refs.loginValidation.validate().then(async success => {
          if (success) {
            this.loading = true
            login(this.userEmail, this.password).then(res => {
                console.log('res login', res)
                // this.$store.dispatch('menuLogin/setMenu', JSON.parse(res.data.menu))
                // console.log(organizzazioni)
                this.loading = false
                if (res.data.esito === 'OK') {
                  // const bookmarks = JSON.parse(res.data.bookmarks)
                  const organizzazioni = [...JSON.parse(JSON.stringify(res.data.organizzazioni))]
                  // const routeOK = JSON.parse(res.data.menu)
                  // const route = JSON.parse(res.data.menu)
                  // console.log('nuovoMenu', route)
                  // const notification = JSON.parse(res.data.navbarnotifiche)
                  sessionStorage.setItem('token', res.data.token)
                  sessionStorage.setItem('organization', JSON.stringify(organizzazioni))
                  sessionStorage.setItem('emailVet', this.userEmail)
                  // sessionStorage.setItem('route', JSON.stringify(route))
                  // sessionStorage.setItem('routeOK', JSON.stringify(routeOK))
                  // sessionStorage.setItem('bookmarks', JSON.stringify(bookmarks))
                  // sessionStorage.setItem('notifications', JSON.stringify(notification))
                  this.setActiveAgency()
                  // store.dispatch('menuLogin/getMenuUtente')
                  /* this.$refs.toastRef.show({
                    title: 'Login effettuato!', content: res.data.messaggio, cssClass: 'e-toast-success', icon: 'e-success toast-icons',
                  }) */
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: res.data.messaggio,
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                    },
                  })
                  // this.$router.push('/dashboard')
                  this.$router.push('/choose-connection').catch(() => {})
                }
                if (res.data.esito === 'KO') {
                  this.loading = false
                  /* this.$refs.toastRef.show({
                    title: 'Errore Login', content: 'Utente non autorizzato', cssClass: 'e-toast-danger', icon: 'e-error toast-icons',
                  }) */
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: res.data.messaggio,
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                    },
                  })
                }
              })
              .catch(e => {
                this.loading = false
                // console.log(e)
                /* this.$refs.toastRef.show({
                  title: 'Errore Login', content: 'Utente non autorizzato', cssClass: 'e-toast-danger', icon: 'e-error toast-icons',
                }) */
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Errore Generico',
                    icon: 'XIcon',
                    variant: 'danger',
                  },
                })
              })
            }
          })
        } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Attenzione!',
                text: 'Sicuro di aver accettato il Recaptcha?',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
            /* this.$bvToast.toast('Errore nell\'inserimento dati, sicuro di aver accettato il Recaptcha?', {
            title: 'Errore!',
            variant: 'danger',
            solid: true,
          }) */
        }
      },
  }
}
</script>

<style lang="scss">
 @import '@core/scss/vue/pages/page-auth.scss';
</style>
<style scoped>
.text-danger {
  font-size: 10px;
}
.bg-sea{
  background-image: url(../@core/assets/login/mare-chiaro.svg) !important;
  background-repeat: no-repeat;
  background-size: cover;
}
.recaptchaNotVisible {
  visibility: hidden;
}
.recaptchaVisible{
  visibility: visible;
}
</style>
