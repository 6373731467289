import axios from 'axios'

export function login(email, password) {
  // console.log('dentro logiiinnn')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER_LOGIN, {
      email, password,
    })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}

export function login2(email, password) {
  // console.log('dentro logiiinnn2')
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_URL_API_SERVER_LOGIN, {
      email, password,
    })
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
}
