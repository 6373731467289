<template>
  <vue-recaptcha
    :sitekey="sitekey"
    :load-recaptcha-script="true"
    @verify="verifyMethod"
    @expired="expiredMethod"
    @error="errorMethod"
  />
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha'

export default {
  components: {
    VueRecaptcha,
  },
  computed: {
    sitekey() {
      return process.env.VUE_APP_SITE_KEY_RECAPTCHA
    },
  },
  methods: {
    expiredMethod() {
      this.$emit('recaptcha-exipered')
      this.$refs.recaptcha.reset()
    },
    verifyMethod() {
      this.$emit('recaptcha-verified')
    },
    errorMethod() {
      this.$bvToast.toast('Errore nel Recaptcha! Aggiorna la pagina', {
        title: 'Errore!',
        variant: 'danger',
        solid: true,
      })
    },
  },
}
</script>

<style>

</style>
